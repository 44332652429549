<template>
    <div id="analyser-result">
        <TabBar :switch-tab="switchTab" :tabs="analysisTypes" :active-tab="currentType" />

        <div id="analyser-result-container">
            <div v-if="error && error.title && error.title.length > 0" class="error">
                <span class="icon-times-circle"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                <p class="title">{{error.title}}</p>
                <p v-if="error.info && error.info.length > 0">{{ error.info }}</p>
            </div>

            <div v-else-if="analysis && analysis.done">
                <SeoResult v-if="currentType === 'SEO'" :scan="analysis.scans.find(scan => scan.type === 'SEO')"/>
                <PerformanceResult v-else-if="currentType === 'Performance'" :scan="analysis.scans.find(scan => scan.type === 'Performance')"/>
            </div>

            <div v-else class="waiting">
                <div v-if="analysis && !analysis.done">
                    <div class="waiting-background">
                        <Lottie :options="{ animationData: writingMangoAnim.default, loop: true, autoplay: true }"
                                :width="350" :height="350" />
                    </div>
                    <p class="title loading-dots" v-if="analysis.new">De analyse wordt uitgevoerd</p>
                    <p class="title" v-else>De analyse wordt opgehaald</p>
                </div>
                <div v-else-if="analysis === null">
                    <div class="waiting-background">
                        <Lottie :options="{ animationData: sliderMangoAnim.default, loop: true, autoplay: true }"
                                :width="350" :height="350" />
                    </div>
                    <p class="title loading-dots">De analyse wordt voorbereid</p>
                </div>
                <div v-else>
                    <div class="waiting-background">
                        <Lottie :options="{ animationData: postalMangoAnim.default, loop: true, autoplay: true }"
                                :width="350" :height="350" />
                    </div>
                    <p class="title">Start een nieuwe analyse</p>
                    <p>Of selecteer een eerder uitgevoerde analyse uit de historie.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TabBar from "./TabBar";
    import SeoResult from "./Seo/SeoResult";
    import PerformanceResult from "./Performance/PerformanceResult";
    import Lottie from 'vue-lottie';

    import * as writingMangoAnim from "../../assets/lottie-writing-mango.json";
    import * as postalMangoAnim from "../../assets/lottie-postal-mango.json";
    import * as sliderMangoAnim from "../../assets/lottie-slider-mango.json";

    export default {
        name: "AnalyserResult",
        data() {
            const analysisTypes = [ 'SEO', 'Performance' ];

            return {
                analysisTypes,
                currentType: analysisTypes[0],

                writingMangoAnim,
                postalMangoAnim,
                sliderMangoAnim

            }
        },
        props: {
            analysis: Object,
            error: Object
        },
        methods: {
            switchTab(newTab) {
                this.currentType = newTab
            }
        },
        components: {
            TabBar,
            SeoResult,
            PerformanceResult,
            Lottie
        }
    }
</script>

<style lang="scss">

    .loading-dots:after {
        overflow: hidden;
        position: absolute;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4,end) 900ms infinite;
        animation: ellipsis steps(4,end) 900ms infinite;
        content: "\2026"; /* ascii code for the ellipsis character */
        width: 0px;
    }

    @keyframes ellipsis {
        to {
            width: 20px;
        }
    }

    @-webkit-keyframes ellipsis {
        to {
            width: 20px;
        }
    }

    #cube-page-analyser #analyser-result {
        display: flex;
        flex-grow: 2;
        flex-direction: column;

        #analyser-result-container {
            display: flex;
            flex-grow: 2;

            > * {
                width: 100%;
            }

            > div > div {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            .waiting, .error {
                display: flex;
                flex-grow: 2;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                padding-top: 20px;
                padding-bottom: 20px;

                > div {
                    justify-content: center;

                    > div {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        margin-left: auto;
                        margin-right: auto;

                        width: 250px;
                        height: 250px;
                        overflow: hidden;

                        border-radius: 400px;

                        > div {
                            min-width: 350px;
                        }
                    }

                    > div.waiting-background {
                        background-color: #DAEBFF;
                    }
                }

                p.title {
                    margin-top: 40px;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: unset;
                }

                p {
                    text-align: center;
                    margin-top: 8px;
                    max-width: 350px;
                    font-size: 14px;
                    line-height: 18px;
                    margin-left: auto;
                    margin-right: auto;
                }

                span.icon-times-circle {
                    font-size: 150px;
                }
            }
        }

        > * {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
</style>
