<template>
    <div class="col-lg-6 col-xl-6 card">
        <div>
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: "Card"
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser .card {
        margin-top: 40px;
        display: flex;

        > div {
            background-color: var(--pa-lightgrey);
            min-height: 320px;
            /*height: 100%;*/
            flex-grow: 2;
            border-radius: 10px;
            padding: 24px;
            display: flex;
            flex-direction: column;
        }
    }
</style>
