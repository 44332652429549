<template>
    <div>
        <div v-if="scan.valid" class="row" id="seo-result">
            <div class="scan-title col-md-12"><h2>SEO resultaten</h2></div>

<!--            <Card>-->
<!--                <CardHeader title="SEO-score" />-->
<!--                <div class="overall_score">-->
<!--                    <ScoreCircle :score="scan.overall_score" />-->
<!--                </div>-->
<!--            </Card>-->

            <Card>
                <CardHeader title="Kansen" />
                <SingleResultInfo indicator="neutral">{{ scan.opportunities }} verbeterpunten</SingleResultInfo>
            </Card>

            <Uri :result="scan.result.uri" />
            <Crawlable :result="scan.result.crawlable" />
            <TitleTag :result="scan.result.title_tag" />
            <Robots_txt :result="scan.result.robots_txt" />
            <WordCount :result="scan.result.word_count" />
            <CanonicalTag :result="scan.result.canonical_tag" />
            <OnPageLinks :result="scan.result.on_page_links" />
            <PageHeadings :result="scan.result.page_headings" />
            <MetaDescription :result="scan.result.meta_description" />
            <FocusKeywordDensity :result="scan.result.focus_keyword_density" />
            <DuplicateContent :uri="scan.result.uri.uri" />
            <SocialMedia :facebook="scan.result.facebook" />
        </div>

        <FailedScan v-else />
    </div>
</template>

<script>
    import Uri from "./ResultBlocks/Uri";
    import Crawlable from "./ResultBlocks/Crawlable";
    import TitleTag from "./ResultBlocks/TitleTag";
    import Robots_txt from "./ResultBlocks/Robots_txt";
    import WordCount from "./ResultBlocks/WordCount";
    import CanonicalTag from "./ResultBlocks/CanonicalTag";
    import OnPageLinks from "./ResultBlocks/OnPageLinks";
    import PageHeadings from "./ResultBlocks/PageHeadings";
    import MetaDescription from "./ResultBlocks/MetaDescription";
    import FocusKeywordDensity from "./ResultBlocks/FocusKeywordDensity";
    import Card from "../Card";
    import CardHeader from "../CardHeader";
    import ScoreCircle from "../ScoreCircle";
    import SingleResultInfo from "../SingleResultInfo";
    import FailedScan from "../FailedScan";
    import DuplicateContent from "./ResultBlocks/DuplicateContent";
    import SocialMedia from "./ResultBlocks/SocialMedia";

    export default {
        name: "SeoResult",
        props: {
            scan: Object
        },
        components: {
            SocialMedia,
            DuplicateContent,
            FailedScan,
            ScoreCircle, // eslint-disable-line vue/no-unused-components
            SingleResultInfo,
            CardHeader,
            Card,
            FocusKeywordDensity,
            MetaDescription,
            PageHeadings,
            Uri,
            Crawlable,
            TitleTag,
            Robots_txt,
            WordCount,
            CanonicalTag,
            OnPageLinks
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser #seo-result.row {
        padding-bottom: 40px;

        h2 {
            margin-bottom: 0 !important;
        }

        .overall_score {
            display: inline-flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }
</style>
