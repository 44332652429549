<template>
    <div>
        <div v-if="scan.valid" class="row" id="performance-result">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-6">
                        <PerformanceScore :score="scan.overall_score" />
                    </div>
                    <div class="col-lg-6">
                        <LoadTimes :loading-metrics="scan.result.loading_metrics_ms"/>
                    </div>
                </div>
            </div>

            <ImageOptimizations :images="scan.result.images_optimizable_size" title="Comprimeer afbeeldingen" />
            <ImageOptimizations :images="scan.result.images_inefficient_format" title="Lever afbeeldingen in moderne indelingen zoals JPEG 2000, JPEG XR en WebP" />
        </div>

        <FailedScan v-else/>
    </div>
</template>

<script>
    import LoadTimes from "./Results/LoadTimes";
    import ImageOptimizations from "./Results/ImageOptimizations";
    import PerformanceScore from "./Results/PerformanceScore";
    import FailedScan from "../FailedScan";

    export default {
        name: "PerformanceResult",
        props: {
            scan: Object
        },
        components: {
            FailedScan,
            ImageOptimizations,
            LoadTimes,
            PerformanceScore
        }
    }
</script>

<style lang="scss">
    #cube-page-analyser #performance-result.row {
        padding-bottom: 40px;

        .image-optimizations {
            margin-bottom: 28px;
        }

        .image-optimizations:last-child {
            margin-bottom: 0;
        }
    }
</style>