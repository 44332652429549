<template>
    <div class="row" id="analyser-tabbar">
        <p v-for="tab in tabs" :key="tab"
           :class="tab === activeTab ? 'active' : ''"
           v-on:click="() => switchTab(tab)"
        >{{ tab }}</p>
    </div>
</template>

<script>
    export default {
        name: "TabBar",
        props: {
            tabs: {
                type: Array,
                required: true
            },
            activeTab: {
                type: String,
                required: true
            },
            switchTab: {
                type: Function,
                required: true
            }
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser #analyser-tabbar {
        height: 50px;
        min-height: 50px;
        padding-bottom: 0;

        display: flex;

        border-bottom: 2px solid var(--pa-bordergrey);

        p {
            display: inline-flex;
            align-items: center;

            margin-right: 30px;
            font-size: 16px;
            font-weight: 400;

            height: calc(100% + 2px);
            margin-bottom: -2px;

            cursor: pointer;

            border-bottom: 2px solid transparent;
        }

        p:first-child {
            margin-left: 15px;
        }

        p.active {
            color: var(--pa-primary);
            border-bottom-color: var(--pa-primary);
        }
    }
</style>
