<template>
    <Card>
        <CardHeader :feedback="result.feedback" title="URL" />
        <BigResultInfo title="URL">{{ result.uri }}</BigResultInfo>
        <div class="medium-result-combi">
            <MediumResultInfo title="HTTP-statuscode">{{ result['status_code'] }}</MediumResultInfo>
            <MediumResultInfo title="HTTPS">{{ result['https'] ? 'Ja' : 'Nee' }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Leesbaarheid">{{ result.readability }}</MediumResultInfo>
            <MediumResultInfo title="Focus trefwoord gevonden">{{ result['focus_keyword'].found ? 'Ja' : 'Nee' }}</MediumResultInfo>
        </div>
    </Card>
</template>

<script>
    import Card from "../../Card";
    import CardHeader from "../../CardHeader";
    import BigResultInfo from "../../BigResultInfo";
    import MediumResultInfo from "../../MediumResultInfo";

    export default {
        name: "Uri",
        props: {
            result: Object
        },
        components: {
            Card,
            CardHeader,
            BigResultInfo,
            MediumResultInfo
        }
    }
</script>

<style scoped>

</style>
