<template>
    <div class="failed-scan">
<!--        <div class="background">-->
<!--            <Lottie :options="{ animationData: jumpingGirlAnim.default, loop: true, autoplay: true }"-->
<!--                    :width="350" :height="350" />-->
<!--        </div>-->
        <p class="title">Er is iets misgegaan bij het uitvoeren van deze scan.</p>
        <p>Voer later een nieuwe analyse uit.</p>
    </div>
</template>

<script>
    import Lottie from 'vue-lottie';

    import * as jumpingGirlAnim from '../../assets/lottie-juming-girl.json';

    export default {
        name: "FailedScan",
        data() {
            return {
                jumpingGirlAnim
            }
        },
        components: {
            Lottie // eslint-disable-line
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser .failed-scan {
        display: flex;
        flex-grow: 2;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        padding: 20px 0;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-left: auto;
            margin-right: auto;

            width: 350px;
            height: 350px;
            overflow: hidden;

            border-radius: 400px;

            background-color: #DAEBFF;

            > div {
                min-width: 350px;
            }
        }

        p.title {
            margin-top: 40px;
            font-size: 24px;
            font-weight: 600;
            line-height: unset;
        }

        p {
            text-align: center;
            margin-top: 8px;
            max-width: 350px;
            font-size: 14px;
            line-height: 18px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>