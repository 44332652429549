<template>
    <Card>
        <CardHeader :feedback="result.feedback" title="Canonical tag"/>
        <SingleResultInfo :indicator="result.feedback.indicator">{{ result.found ? 'Gevonden!' : 'Niet gevonden' }}</SingleResultInfo>
    </Card>
</template>

<script>
    import Card from "../../Card";
    import CardHeader from "../../CardHeader";
    import SingleResultInfo from "../../SingleResultInfo";

    export default {
        name: "CanonicalTag",
        props: {
            result: Object
        },
        components: {
            SingleResultInfo,
            Card,
            CardHeader
        }
    }
</script>

<style scoped>
</style>