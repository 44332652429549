<template>
    <div class="score-container">
        <vc-donut :sections="[{ value: score, color: circleColor }]"
                  :size="220" :thickness="28"
                  background="#f4f4f4" foreground="#ffffff" :total="100"
        ><p class="score-text">{{score}}%</p></vc-donut>
    </div>
</template>

<script>
    export default {
        name: "ScoreCircle",
        props: {
            score: {
                type: Number,
                required: true
            }
        },
        computed: {
            circleColor() {
                if(this.score >= 90) {
                    return '#73C914';
                } else if(this.score >= 50) {
                    return '#FF9F00';
                } else {
                    return '#FF001F';
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser div.score-container {
        .score-text {
            font-size: 32px;
            font-weight: 600;
        }
    }
</style>