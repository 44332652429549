<template>
    <div class="single-result-info-container">
        <div :class="`indicator-${indicator}`" class="single-result-info">
            <p><slot /></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SingleResultInfo",
        props: {
            indicator: {
                type: String,
                default: 'neutral'
            }
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser .single-result-info-container {
        display: flex;
        flex-grow: 2;
        align-items: center;
        justify-content: center;

        > div.single-result-info {
            margin-top: -32px;

            display: inline-block !important;
            padding: 7px 14px;

            border-radius: 2px;

            p {
                display: inline-block;
                color: white;
                text-align: center;
                font-size: 15px;
            }
        }

        > div.indicator-positive {
            background-color: var(--pa-brightgreen);
        }
        > div.indicator-negative {
            background-color: var(--pa-red);
        }
        > div.indicator-couldhave {
            background-color: var(--pa-orange);
        }
        > div.indicator-neutral {
            background-color: var(--pa-darkgrey);
        }
    }
</style>
