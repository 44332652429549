<template>
    <form v-if="baseUri && slugs" class="row" id="analyser-analyser" v-on:submit="event => {
            event.preventDefault();
            if(!analysing) analyse();
          }">
        <div class="col-md-6 col-lg-3 strategy-switch">
            <input type="radio" id="mobile-strategy" name="strategy" value="mobile" required
                   v-model="strategy"  :disabled="analysing" />
            <label for="mobile-strategy">
                Mobiel
            </label>
            <input type="radio" id="desktop-strategy" name="strategy"  value="desktop" required
                   v-model="strategy"  :disabled="analysing" />
            <label for="desktop-strategy">
                Desktop
            </label>
        </div>
        <div class="col-md-6 col-lg-4">
            <div class="uri-selector">
                <span class="icon-page"></span>
                <p>{{ formattedDomain }}</p>
                <select id="slug-selector" name="slug" v-model="selectedSlug" :disabled="analysing">
                    <option v-for="(slug, index) in slugs" :key="slug"
                            :selected="selectedSlug.length > 0 ? formatSlug(slug) === selectedSlug : index === 0"
                            :value="formatSlug(slug)">{{ formatSlug(slug) }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6 col-lg-3">
            <label class="focus-keyword-input">
                <span class="icon-bookmark"></span>
                <input type="text" maxlength="100" placeholder="Focus trefwoord" name="focus-keyword"
                       v-model="focusKeyword"  :disabled="analysing" />
            </label>
        </div>
        <div class="col-md-6 col-lg-2 start-analysing-button">
            <button type="submit" :disabled="analysing">
                {{ analysing ? 'Bezig...' : 'Start analyse' }}
            </button>
        </div>
    </form>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "StartAnalysis",
        data() {
            return {
                strategy: "mobile",
                selectedSlug: "",
                focusKeyword: "",
                analysing: false
            }
        },
        props: {
            baseUri: String,
            slugs: Array,
            setAnalysis: {
                type: Function,
                required: true
            },
            handleError: {
                type: Function,
                required: true
            }
        },
        computed: {
            formattedDomain() {
                const uri = this.baseUri.toLowerCase();
                const lastCharacter = uri.slice(-1);

                if(lastCharacter.match(/[a-z|/]/i) === null)
                    console.error('Invalid base URI', this.baseUri); // eslint-disable-line no-console

                return lastCharacter === '/' ? uri.slice(0, uri.length - 1) : uri;
            }
        },
        methods: {
            analyse: async function() {
                this.analysing = true;
                this.setAnalysis(null);

                try {
                    const resp = (await axios.post('analysis', {
                        'uri': this.formattedDomain + this.selectedSlug,
                        'focus-keyword': this.focusKeyword,
                        'strategy': this.strategy
                    }));

                    const result = resp.data;

                    this.setAnalysis({
                        ...result,
                        hideDateInfo: true
                    });
                } catch(e) {
                    this.setAnalysis(undefined);
                    this.handleError(e);
                }

                this.analysing = false;
            },
            formatSlug(slug) {
                const firstCharacter = slug.slice(0, 1);

                return firstCharacter === '/' ? slug : '/' + slug;
            }
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser #analyser-analyser {
        padding-top: 40px;
        padding-bottom: 40px;

        border-bottom: 2px solid var(--pa-bordergrey);
        margin-bottom: 0;

        .strategy-switch {
            label {
                border: 1px solid var(--pa-primary);
                background-color: unset;

                width: 50%;
                height: 40px;

                display: inline-flex;
                justify-content: center;
                align-items: center;

                color: var(--pa-primary);

                cursor: pointer;
            }

            label[for=mobile-strategy] {
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
                border-right-width: 0.5px;
            }

            label[for=desktop-strategy] {
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
                border-left-width: 0.5px;
            }

            label:active, label:focus,
            input#mobile-strategy:checked ~ label[for=mobile-strategy],
            input#desktop-strategy:checked ~ label[for=desktop-strategy] {
                background-color: var(--pa-primary);
                color: var(--pa-white);
            }

            input {
                display: none;
            }
        }

        .uri-selector {
            border-radius: 5px;
            background-color: var(--pa-lightgrey);

            padding-left: 15px;
            padding-right: 15px;

            display: flex;
            align-items: center;

            height: 40px;
            width: 100%;

            span:first-child {
                margin-right: 13px;
            }

            p {
                font-size: 14px;
                display: inline-block;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                min-width: 100px;
                direction: rtl;
            }

            select {
                margin: 0;
                padding: 0;

                appearance: none;

                background: url(../assets/Dropdown_arrow.svg) right center no-repeat transparent;

                border: none;

                overflow: hidden;
                white-space: nowrap;
                width: 10px;

                flex-grow: 2;

                cursor: pointer;
            }

            svg:last-child {
                margin-left: auto;
            }
        }

        .focus-keyword-input {
            border-radius: 5px;
            background-color: var(--pa-lightgrey);

            padding-left: 15px;
            padding-right: 15px;

            height: 40px;
            width: 100%;

            display: flex;
            align-items: center;

            input {
                appearance: none;
                background-color: transparent;
                border: none;

                flex-grow: 2;
            }

            span:first-child {
                margin-right: 13px;
            }
        }

        .start-analysing-button {
            button {
                width: 100%;

                background-color: var(--pa-primary);
                height: 40px;

                border-radius: 5px;
                border: 1px solid var(--pa-primary);

                color: var(--pa-white);
                font-size: 14px;

                cursor: pointer;

                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.02);
            }

            button:disabled {
                background-color: var(--pa-darkgrey);
                border-color: var(--pa-darkgrey);
                cursor: unset;
            }
        }
    }
</style>
