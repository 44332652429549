<template>
    <div>
        <h4>{{ title }}</h4>
        <p><slot /></p>
    </div>
</template>

<script>
    export default {
        name: "BigResultInfo",
        props: {
            title: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser div {
        width: 100%;
        padding: 8px;
        background-color: var(--pa-white);
        border-radius: 2px;
        margin-top: 8px;

        box-shadow: 0 2px 2 0 var(--pa-black);

        h4 {
            font-size: 15px;
            margin-bottom: 6px;
            font-weight: 700;
        }

        p {
            font-size: 15px;
            line-height: 18px;
            min-height: 34px;
        }
    }
</style>