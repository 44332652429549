<template>
    <div id="analyser-analysis-header" class="row">
        <p>Analyse:</p>
        <div v-if="!analysis.hideDateInfo">
            <span class="icon-calendar"></span>
            <p>Datum</p>
            <p>{{ analysisDate }}</p>
        </div>
        <div>
            <span class="icon-screen"></span>
            <p>Device</p>
            <p>{{ analysis.strategy === 'mobile' ? 'Mobiel' : 'Desktop' }}</p>
        </div>
        <div>
            <span class="icon-page"></span>
            <p>Pagina</p>
            <p>{{ analysis.analysed_uri}}</p>
        </div>
        <div v-if="analysis.focus_keyword">
            <span class="icon-bookmark"></span>
            <p>Focus trefwoord</p>
            <p>{{ analysis.focus_keyword}}</p>
        </div>
    </div>
</template>

<script>
    import moment from 'moment-timezone';

    export default {
        name: "CurrentAnalysisHeader",
        props: {
            analysis: {
                type: Object,
                required: true
            }
        },
        computed: {
            analysisDate() {
                const finishedAt = moment(this.analysis.updated_at);
                finishedAt.locale('nl');
                finishedAt.tz('Europe/Amsterdam');
                return finishedAt.format('D MMMM YYYY HH:mm:ss');
            }
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser #analyser-analysis-header {
        min-height: 122px;

        border-bottom: 2px solid var(--pa-bordergrey);

        display: flex;
        align-items: center;

        padding: 24px 40px !important;

        background-color: #F6F6F6;

        > p {
            font-weight: 600;
            font-size: 16px;
            color: var(--pa-darkgrey);

            margin-right: 12px;
        }

        div {
            display: flex;
            align-items: center;

            background-color: var(--pa-white);
            padding: 10px 12px;

            margin-left: 8px;

            span {
                font-size: 13px;
                color: var(--pa-darkgrey);
            }

            p {
                font-size: 15px;
                margin-left: 8px;
            }

            p:first-child {
                font-weight: 600;
                color: var(--pa-darkgrey)
            }

            p:last-child {
                color: #6A6A6A;
            }
        }
    }
</style>
