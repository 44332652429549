<template>
    <Card>
        <CardHeader title="Social media" />
        <BigResultInfo title="Facebook">{{facebook.engagement}} like en deel acties</BigResultInfo>
    </Card>
</template>

<script>
    import CardHeader from "../../CardHeader.vue";
    import Card from "../../Card.vue";
    import BigResultInfo from "../../BigResultInfo";

    export default {
        name: "SocialMedia",
        props: {
            facebook: {
                type: Object,
                required: true
            }
        },
        computed: {
        },
        components: {
            BigResultInfo,
            CardHeader,
            Card
        }
    }
</script>

<style scoped lang="scss">
</style>
