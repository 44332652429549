<template>
  <div class="container-fluid" id="cube-page-analyser">
    <Menu :active="activeMenu" :newAnalysis="newAnalysis" :open-history="() => historyOpen = true" />

    <CurrentAnalysisHeader v-if="analysis && activeMenu !== 'history'" :analysis="analysis"/>
    <StartAnalysisHeader v-else-if="activeMenu !== 'history'" :set-analysis="setAnalysis" :handle-error="handleError"
                         :base-uri="domain" :slugs="slugs"
    />

    <AnalyserResult :analysis="analysis" :error="error" v-if="activeMenu !== 'history'" />

    <History v-if="activeMenu === 'history'" :close-history="() => historyOpen = false" :set-analysis="setAnalysis" />
  </div>
</template>

<script>
import Menu from "./components/Menu.vue";
import StartAnalysisHeader from "./components/StartAnalysisHeader";
import AnalyserResult from "./components/AnalysisResult/AnalyserResult";
import CurrentAnalysisHeader from "./components/CurrentAnalysisHeader";

import axios from 'axios';
import History from "./components/History";

let checkAnalysisStatusInterval;

export default {
  name: 'app',
  data() {
    return {
      analysis: undefined,
      error: undefined,
      historyOpen: false,
      domain: undefined,
      slugs: undefined
    }
  },
  computed: {
    activeMenu() {
      if(this.historyOpen) {
        return 'history'
      } else if(!this.analysis ) {
        return 'newAnalysis';
      } else {
        return '';
      }
    }
  },
  methods: {
    setAnalysis(analysis) {
      this.error = undefined;

      clearInterval(checkAnalysisStatusInterval);

      if(analysis && !analysis.done) {
          this.analysis = {
              ...analysis,
              new: true
          };

          localStorage.setItem('not-done-analysis', this.analysis.id);
          this.waitForAnalysis();
          checkAnalysisStatusInterval = setInterval(this.waitForAnalysis, 3000)
      } else {
          this.analysis = analysis;
      }
    },
    async waitForAnalysis() {
      if(this.analysis === undefined) {
        clearInterval(checkAnalysisStatusInterval);
        localStorage.removeItem('not-done-analysis');
        return;
      }

      try {
        const result = await axios.get(`analysis/${this.analysis.id}`);
        const { data } = result;

        if(data.done) {
          clearInterval(checkAnalysisStatusInterval);
          localStorage.removeItem('not-done-analysis');
          this.analysis = {
            ...this.analysis,
            ...data
          };
        }
      } catch(e) {
        this.handleError(e);
        this.analysis = undefined;
        clearInterval(checkAnalysisStatusInterval);
        localStorage.removeItem('not-done-analysis');
      }
    },
    handleError(error) {
      if(error && error.response) {
        const { status, data } = error.response;
        switch (status) {
          case 401:
            this.error = {
                title: "Er is iets misgegaan met de authenticatie",
                info: "Herlaad de pagina en probeer het opnieuw."
            };
            break;
          case 422:
            if(data && data.error) {
              this.error = {
                  title: "Er is iets misgegaan",
                  info: data.error
              };
              break;
            }
          case 403: // eslint-disable-line no-fallthrough
            this.error = {
                title: "Er zijn geen analyses meer beschikbaar",
                info: "Alle beschikbare analyses voor deze maand zijn verbruikt."
            };
            break;
          default:
            this.error = {
                title: "Er is iets misgegegaan",
                info: "Herlaad de pagina en probeer het opnieuw."
            }
        }
      } else {
          this.error = {
              title: "Er is iets misgegegaan",
              info: "Herlaad de pagina en probeer het opnieuw."
          }
      }
    },
    newAnalysis() {
      if(this.analysis !== null) {
        this.analysis = undefined;
        this.historyOpen = false;
      }
    },
    async checkForUnfinishedAnalysis() {
      const analysingId = localStorage.getItem('not-done-analysis');
      if(analysingId) {
        try {
          const result = await axios.get(`analysis/${analysingId}`);
          const { data } = result;

          this.analysis = data;

          if(data.done) {
            localStorage.removeItem('not-done-analysis');
          } else {
            this.analysis = {
              ...this.analysis,
              new: true
            };
            checkAnalysisStatusInterval = setInterval(this.waitForAnalysis, 3000)
          }
        } catch(e) {
          this.handleError(e);
          clearInterval(checkAnalysisStatusInterval);
          localStorage.removeItem('not-done-analysis');
        }
      }
    },
    setConfigData() {
      const customerToken = window.cubePaCustomerToken;
      const domain = window.cubePaDomain;
      const paths = window.cubePaPaths;
      const sitemapUri = window.cubePaSitemapUri;

      if(domain && customerToken && (paths || sitemapUri)) {
        axios.defaults.headers['x-token'] = customerToken;
        this.domain = domain;

        if(paths) {
            this.slugs = paths;
        } else {
            const lsKey = `cpa-${decodeURIComponent(sitemapUri)}`;
            let cachedPaths = localStorage.getItem(lsKey);

            this.slugs = [];
            if(!cachedPaths) {
                this.retrieveSitemapPaths(sitemapUri, lsKey);
            } else {
                cachedPaths = JSON.parse(cachedPaths);

                const currentTimestamp = Date.now() / 1000;
                if((cachedPaths.timestamp + 90000) > currentTimestamp) {
                    this.slugs = cachedPaths.paths;
                } else {
                    this.retrieveSitemapPaths(sitemapUri, lsKey);
                }
            }
        }

      } else {
        this.error = {
            title: "Er is iets misgegaan",
            info: "De tool is niet goed geïmplementeerd."
        };
        console.error('The variables customerToken, domain and paths are not supplied.') // eslint-disable-line no-console
      }
    },
    retrieveSitemapPaths(sitemapUri, lsKey) {
        axios.get('sitemap-slugs', {
            params: {
                'sitemap-uri': sitemapUri
            }
        })
            .then(resp => {
                this.slugs = resp.data;

                const currentTimestamp = Date.now() / 1000;
                localStorage.setItem(lsKey, JSON.stringify({
                    timestamp: currentTimestamp,
                    paths: this.slugs
                }));
            })
            .catch(() => {
                this.error = {
                    title: 'Er is iets misgegaan bij het ophalen van de beschikbare pagina\'s.',
                    info: 'Herlaad de pagina.'
                };
                this.paths = [];
            });
    }
  },
  mounted() {
    this.setConfigData();
    this.checkForUnfinishedAnalysis();
  },
  components: {
    History,
    CurrentAnalysisHeader,
    Menu,
    StartAnalysisHeader,
    AnalyserResult
  }
}
</script>

<style lang="scss">
  #cube-page-analyser {
    background-color: var(--pa-white);

    display: flex;
    flex-direction: column;

    height: 100vh;
    overflow-y: auto;

    > .row {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
</style>
