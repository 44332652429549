<template>
    <div id="analyser-history" class="row">
        <div class="col-md-12">
            <h2>Uitgevoerde analyses</h2>

            <div v-if="!history" class="waiting">
                <div class="waiting-background">
                    <Lottie :options="{ animationData: postalMangoAnim.default, loop: true, autoplay: true }"
                            :width="350" :height="350" />
                </div>
                <p class="title">De historie wordt opgehaald.</p>
            </div>

            <table v-else-if="history && history.length > 0">
                <thead>
                <tr>
                    <th>Datum</th>
                    <th>Pagina</th>
                    <th>Focus trefwoord</th>
                    <th>Device</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="analysis in history" :key="analysis.id">
                    <td>{{ formatDate(analysis.updated_at) }}</td>
                    <td>{{ analysis.analysed_uri }}</td>
                    <td>{{ analysis.focus_keyword }}</td>
                    <td>{{ analysis.strategy === 'mobile' ? 'mobiel' : 'desktop' }}</td>
                    <td><span v-on:click="() => openAnalysis(analysis.id)">Toon resultaten</span></td>
                </tr>
                </tbody>
            </table>

            <p v-else>Er zijn nog geen analyses uitgevoerd.</p>
        </div>
    </div>
</template>

<script>
    import Lottie from 'vue-lottie';
    import * as postalMangoAnim from '../assets/lottie-postal-mango.json';

    import axios from 'axios';
    import moment from 'moment-timezone';

    export default {
        name: "History",
        data() {
            return {
                history: undefined,
                postalMangoAnim
            }
        },
        props: {
            closeHistory: {
                type: Function,
                required: true
            },
            setAnalysis: {
                type: Function,
                required: true
            }
        },
        methods: {
            openAnalysis(analysisId) {
                this.setAnalysis({
                    ...this.history.find(analysis => analysis.id === analysisId),
                    done: false
                });
                this.closeHistory();
            },
            formatDate(date) {
                const momentDate = moment(date);
                momentDate.locale('nl');
                momentDate.tz('Europe/Amsterdam');
                return momentDate.format('D MMMM YYYY HH:mm:ss');
            }
        },
        async mounted() {
            const result = await axios.get('analysis');

            this.history = result.data;
        },
        components: {
            Lottie
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser #analyser-history {
        background-color: var(--pa-white);
        padding-top: 20px;
        padding-bottom: 20px;

        display: flex;
        flex-grow: 2;

        div {
            display: flex;
            flex-grow: 2;
            flex-direction: column;

            p {
                font-size: 15px;
                font-style: italic;
            }

            table {
                tbody {
                    td {
                        color: var(--pa-darkgrey);
                    }

                    td:last-child {
                        text-align: right;
                        span {
                            font-weight: 600;
                            color: var(--pa-primary);
                            cursor: pointer;
                        }
                    }
                }
            }

            .waiting, .error {
                display: flex;
                flex-grow: 2;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                padding-top: 20px;
                padding-bottom: 20px;

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;

                    margin-left: auto;
                    margin-right: auto;

                    width: 250px;
                    height: 250px;
                    max-height: 250px;
                    overflow: hidden;

                    border-radius: 400px;

                    background-color: #DAEBFF;

                    > div {
                        min-width: 350px;
                    }
                }

                p.title {
                    margin-top: 40px;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: unset;
                    font-style: normal;
                }
            }
        }
    }
</style>
