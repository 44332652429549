<template>
    <Card>
        <CardHeader title="Dubbele content" />
        <p>Klik <a :href="sitelinerLink" target="_blank">hier</a> om de dubbele content op deze pagina te bekijken.</p>
    </Card>
</template>

<script>
    import CardHeader from "../../CardHeader.vue";
    import Card from "../../Card.vue";

    export default {
        name: "DuplicateContent",
        props: {
            uri: {
                type: String,
                required: true
            }
        },
        computed: {
            sitelinerLink() {
                const url = new URL(this.uri);

                const protocol = url.protocol.split(':')[0];

                let pathname = url.pathname;

                const lastChar = pathname.slice('/');
                if(lastChar === '/')
                    pathname = pathname.substring(0, pathname.length - 1);

                return `http://siteliner.com/${url.hostname}${pathname}?siteliner-scheme=${protocol}`;
            }
        },
        components: {
            CardHeader,
            Card
        }
    }
</script>

<style scoped lang="scss">
    p {
        font-size: 16px !important;
        a {
            color: var(--pa-primary) !important;
            text-decoration: none;

            &:hover, &:active {
                text-decoration: underline;
            }
        }
    }
</style>
