<template>
    <Card>
        <CardHeader :feedback="result.feedback" title="Title tag" />
        <BigResultInfo title="Titel">{{ result.content }}</BigResultInfo>
        <div class="medium-result-combi">
            <MediumResultInfo title="Gevonden">{{ result.found ? 'Ja' : 'Nee' }}</MediumResultInfo>
            <MediumResultInfo title="Lengte">{{ result['length'] }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Focus trefwoord gevonden">{{ result.focus_keyword.found ? 'Ja' : 'Nee' }}</MediumResultInfo>
        </div>
    </Card>
</template>

<script>
    import Card from "../../Card";
    import CardHeader from "../../CardHeader";
    import MediumResultInfo from "../../MediumResultInfo";
    import BigResultInfo from "../../BigResultInfo";

    export default {
        name: "TitleTag",
        props: {
            result: Object
        },
        components: {
            Card,
            CardHeader,
            MediumResultInfo,
            BigResultInfo
        }
    }
</script>

<style scoped>

</style>
