<template>
    <Card>
        <CardHeader :feedback="result.feedback" title="Pagina kopteksten" />
        <BigResultInfo title="H1">{{ result.h1_content }}</BigResultInfo>
        <div class="medium-result-combi">
            <MediumResultInfo title="Aantal H1">{{ result.headers_count.h1 }}</MediumResultInfo>
            <MediumResultInfo title="Aantal H2">{{ result.headers_count.h2 }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Aantal H3">{{ result.headers_count.h3 }}</MediumResultInfo>
            <MediumResultInfo title="Aantal H4">{{ result.headers_count.h4 }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Aantal H5">{{ result.headers_count.h5 }}</MediumResultInfo>
            <MediumResultInfo title="Aantal H6">{{ result.headers_count.h6 }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Focus trefwoord gevonden">{{ result.focus_keyword.found ? 'Ja' : 'Nee' }}</MediumResultInfo>
        </div>
    </Card>
</template>

<script>
    import Card from "../../Card";
    import CardHeader from "../../CardHeader";
    import MediumResultInfo from "../../MediumResultInfo";
    import BigResultInfo from "../../BigResultInfo";

    export default {
        name: "PageHeadings",
        props: {
            result: Object
        },
        components: {
            BigResultInfo,
            Card,
            CardHeader,
            MediumResultInfo
        }
    }
</script>

<style scoped>

</style>