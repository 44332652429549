<template>
    <Card>
        <CardHeader :feedback="result.feedback" title="Links op de pagina" />
        <div class="medium-result-combi">
            <MediumResultInfo title="Totaal aantal links">{{ result.links_count.total }}</MediumResultInfo>
            <MediumResultInfo title="Aantal interne links">{{ result.links_count.internal }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Aantal externe links">{{ result.links_count.external }}</MediumResultInfo>
            <MediumResultInfo title="Aantal dubbele links">{{ result.links_count.duplicate }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Aantal nofollow links">{{ result.links_count.nofollow }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Afbeeldingen zonder alt-tekst">{{ result.images_without_alt_count }}</MediumResultInfo>
        </div>
    </Card>
</template>

<script>
    import Card from "../../Card";
    import CardHeader from "../../CardHeader";
    import MediumResultInfo from "../../MediumResultInfo";

    export default {
        name: "OnPageLinks",
        props: {
            result: Object
        },
        components: {
            Card,
            CardHeader,
            MediumResultInfo
        }
    }
</script>

<style scoped>

</style>
