<template>
    <Card>
        <CardHeader :feedback="result.feedback" title="Aantal woorden" />
        <div class="medium-result-combi">
            <MediumResultInfo title="Totaal">{{ result.word_count.total }}</MediumResultInfo>
            <MediumResultInfo title="Zonder link">{{ result.word_count.without_anchor }}</MediumResultInfo>
        </div>
        <div class="medium-result-combi">
            <MediumResultInfo title="Met link">{{ result.anchor.word_count }}</MediumResultInfo>
            <MediumResultInfo title="Percentage woorden met link">{{ anchorPercentage }}%</MediumResultInfo>
        </div>
    </Card>
</template>

<script>
    import Card from "../../Card";
    import CardHeader from "../../CardHeader";
    import MediumResultInfo from "../../MediumResultInfo";

    export default {
        name: "WordCount",
        props: {
            result: Object
        },
        components: {
            Card,
            CardHeader,
            MediumResultInfo
        },
        computed: {
            anchorPercentage() {
                return this.result.anchor.percentage.toLocaleString('nl-NL', {
                    maximumFractionDigits: 2
                });
            }
        }
    }
</script>

<style scoped>

</style>
