<template>
    <div class="col-md-12 image-optimizations">
        <h2>{{ title }}</h2>

        <div class="table-responsive" v-if="images.images.length > 0">
            <table>
                <thead>
                    <tr>
                        <th colspan="2">Afbeelding</th>
                        <th class="size">Formaat</th>
                        <th class="size">Te besparen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="image in images.images" :key="image.total_bytes + image.wasted_bytes">
                        <td><img :src="image.uri" :alt="image.uri" /></td>
                        <td class="uri">
                            {{ uriPath(image.uri) }}<br />
                            <span>({{ uriDomain(image.uri )}})</span>
                        </td>
                        <td class="size">{{ formatSize(image.total_bytes) }}</td>
                        <td class="size">{{ formatSize(image.wasted_bytes) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="nothing-av" v-else>
            <p>Er zijn geen afbeeldingen die verbeterd kunnen worden.</p>
            <p class="size">&mdash;</p>
            <p class="size">&mdash;</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ImageOptimizations",
        props: {
            images: {
                type: Object,
                required: true
            },
            title: {
                type: String,
                required: true
            }
        },
        methods: {
            uriDomain(uri) {
                const domain = uri.split('/')[2];
                return domain;
            },
            uriPath(uri) {
                return uri;
            },
            formatSize(size) {
                const kb = size / 1000;
                if(kb > 1000) {
                    return `${formatNumber(kb / 1000)} MB`
                } else {
                    return `${formatNumber(kb)} kB`
                }
            }
        }
    }

    const formatNumber = (number) => number.toLocaleString('nl-NL', {
        maximumFractionDigits: 2
    })
</script>

<style scoped lang="scss">
    #cube-page-analyser .image-optimizations {
        .size {
            min-width: 120px;
            width: 120px;
        }

        table {
            tbody {
                tr {
                    td {
                        max-width: 450px;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-align: left;
                    }

                    td:first-child {
                        max-width: unset;
                    }

                    .uri {
                        padding-right: 40px;
                        direction: rtl;

                        span {
                            color: #6A6A6A;
                            line-height: 24px;
                        }
                    }
                }
            }

            img {
                max-height: 48px;
                max-width: 48px;
            }
        }

        .nothing-av {
            display: flex;
            flex-direction: row;

            padding: 16px 0;
            border-bottom: 2px solid var(--pa-bordergrey);
            border-top: 2px solid var(--pa-bordergrey);
            border-radius: 1px;

            p {
                padding-left: 4px;
                padding-right: 4px;
                font-size: 15px;
            }

            p:first-child {
                flex-grow: 2;
                padding-left: 0;
            }

            p:last-child {
                padding-right: 0;
            }
        }
    }
</style>