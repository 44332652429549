<template>
    <div>
        <div style="width: 100%;">
            <h3>
                {{ title }}
                <span class="icon-check-mark" v-if="feedback && feedback.indicator === 'positive'"></span>
                <span class="icon-exclamation-circle" v-else-if="feedback && feedback.indicator === 'couldhave'"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                <span class="icon-times-circle" v-else-if="feedback && feedback.indicator === 'negative'"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
            </h3>

        </div>
        <div v-if="feedback.feedback.length" class="feedback-content">
            <p v-if="feedback.feedback.length === 1">{{ feedback.feedback[0] }}</p>
            <ul v-else>
                <li v-for="fbItem in feedback.feedback" :key="fbItem">{{ fbItem }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Popper from 'popper.js';

    export default {
        name: "CardHeader",
        data() {
            return {
                tooltipShown: false
            }
        },
        props: {
            feedback: Object,
            title: {
                type: String,
                required: true
            }
        },
        mounted() {
            if(this.feedbackAvailable) {
                const button = document.querySelector(`#fb-button-${this.idTitle}`);
                const popper = document.querySelector(`#fb-content-${this.idTitle}`);

                new Popper(button, popper, {
                    placement: 'bottom',
                })
            }
        },
        computed: {
            idTitle() {
                return this.title.replace(/\.| /gi, '_')
            },
            feedbackAvailable() {
                return this.feedback && this.feedback.feedback
            }
        },
        methods: {
            showTooltip() {
                this.tooltipShown = true;
            },
            hideTooltip() {
                this.tooltipShown = false;
            }
        }
    }
</script>

<style scoped lang="scss">
    .feedback-content {
        margin: 10px 0;
        ul {
            padding-left: 20px;
        }
    }
    #cube-page-analyser > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 32px;

        h3 {
            font-size: 21px;
            font-weight: 600;
        }

        [class^="icon-"] {
            margin-left: 12px;
        }

        button {
            padding: 4px 6px;
            border-radius: 3px;
            border: none;

            background-color: var(--pa-darkgrey);

            font-size: 14px;
            color: var(--pa-white);
            /*font-weight: 600;*/

            margin-left: auto;

            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.02);

            cursor: pointer;
        }

        button.tooltip-visible {
            background-color: #404040
        }

        .feedback-popper {
            z-index: 1;
            max-width: 440px;

            visibility: hidden;

            .tooltip-content {
                background-color: var(--pa-white);
                padding: 15px;
                border-radius: 5px;
                box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.15);
            }

            [x-arrow] {
                position: absolute;
                left: 0;

                width: 10px;
                height: 10px;
                background-color: var(--pa-white);
                transform: rotate(45deg);
            }

            p, ul li {
                color: var(--pa-black);
                font-size: 15px;
            }

            ul {
                padding-left: 17px;
                margin-left: 0;
            }
        }

        .feedback-popper.tooltip-visible {
            visibility: unset;
        }

        .feedback-popper[x-placement="bottom"] {
            padding-top: 10px;
            [x-arrow] {
                left: -10px;
                top: 5px;
            }
        }
    }
</style>
