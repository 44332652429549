<template>
    <div>
        <h4>{{ title }}</h4>
        <p><slot /></p>
    </div>
</template>

<script>
    export default {
        name: "MediumResultInfo",
        props: {
            title: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser div {
        width: calc(50% - 4px);
        padding: 8px;
        background-color: var(--pa-white);
        border-radius: 2px;
        margin-top: 8px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        box-shadow: 0 2px 2 0 var(--pa-black);

        h4 {
            font-size: 15px;
            font-weight: 600;
        }

        p {
            font-size: 15px;
        }
    }
</style>