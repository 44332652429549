<template>
    <div id="analyser-load-times">
        <h2>Laadtijden</h2>

        <div class="table-responsive">
            <table>
                <tbody>
                    <tr>
                        <th>
                            Eerste weergave met content (FCP)
                        </th>
                        <td><span>{{ formatMetric(loadingMetrics.first_contentful_paint) }}</span></td>
                    </tr>
                    <tr>
                        <th>Snelheidsindex</th>
                        <td><span>{{ formatMetric(loadingMetrics.speed_index) }}</span></td>
                    </tr>
                    <tr>
                        <th>Tijd tot interactief</th>
                        <td><span>{{ formatMetric(loadingMetrics.time_to_interactive) }}</span></td>
                    </tr>
                    <tr>
                        <th>Eerste nuttige weergave (FMP)</th>
                        <td><span>{{ formatMetric(loadingMetrics.first_meaningful_paint) }}</span></td>
                    </tr>
                    <tr>
                        <th>Eerste keer dat CPU inactief was</th>
                        <td><span>{{ formatMetric(loadingMetrics.first_cpu_idle) }}</span></td>
                    </tr>
                    <tr>
                        <th>Max. potentiële eerste invoervertraging</th>
                        <td><span>{{ formatMetric(loadingMetrics.max_potential_first_input_delay) }}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoadTimes",
        props: {
            loadingMetrics: {
                type: Object,
                required: true
            }
        },
        methods: {
            formatMetric(metric) {
                if(metric >= 1000) {
                    return `${formatNumber(metric / 1000)} seconden`;
                } else {
                    return `${formatNumber(metric)} milliseconden`
                }
            }
        }
    }

    const formatNumber = (number) => number.toLocaleString('nl-NL', {
        maximumFractionDigits: 2
    })
</script>

<style scoped lang="scss">
    #cube-page-analyser #analyser-load-times {
        table {
            th {
                text-align: left;
                font-weight: normal;
                color: var(--pa-darkgrey);
            }

            td {
                text-align: right;

                span {
                    background-color: var(--pa-darkgrey);
                    color: white;
                    border-radius: 4px;
                    padding: 6px;
                    line-height: 27px;
                }
            }
        }
    }
</style>