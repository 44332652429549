<template>
    <div class="row" id="analyser-header">
        <div class="col">
            <div class="logo" v-on:click="newAnalysis">
                <span class="icon-eye"></span>
                <h1>Pagina analyse</h1>
            </div>
        </div>
        <div class="col menu">
            <p class="menu-item" :class="active === 'newAnalysis' ? 'active' : ''" v-on:click="newAnalysis">Nieuwe analyse</p>
            <p class="menu-item" :class="active === 'history' ? 'active' : ''" v-on:click="openHistory">Uitgevoerde analyses</p>
            <span class="icon-close-button" id="cube-pa-menu-close-button"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Menu",
        props: {
            active: String,
            openHistory: {
                type: Function,
                required: true
            },
            newAnalysis: {
                type: Function,
                required: true
            }
        }
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser #analyser-header {
        height: 105px;
        min-height: 105px;

        z-index: 2;

        border-bottom: 2px solid var(--pa-bordergrey);

        display: flex;
        align-items: center;

        background-color: var(--pa-white);

        .logo {
            display: inline-flex;
            align-items: center;

            cursor: pointer;

            .icon-eye {
                font-size: 20px;
            }

            h1 {
                font-size: 16px;
                display: inline-block;
                margin-left: 8px;
            }
        }

        .menu {
            text-align: right;

            display: flex;
            justify-content: flex-end;
            align-items: center;

            > * {
                margin-left: 40px;
            }

            .menu-item {
                color: var(--pa-darkgrey);
                text-decoration: none;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
            }

            .menu-item:hover, .menu-item.active {
                color: var(--pa-primary);
            }

            .icon-close-button {
                height: 24px;
                width: 24px;

                border: 1px solid var(--pa-darkgrey);
                border-radius: 12px;

                color: var(--pa-darkgrey);

                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 25px;

                cursor: pointer;
            }
        }
    }
</style>