<template>
    <Card>
        <CardHeader :feedback="result.feedback" title="Focus trefwoord"/>
        <BigResultInfo title="Trefwoord">{{ result.keyword }}</BigResultInfo>
        <div class="medium-result-combi">
            <MediumResultInfo title="Frequentie">{{ result.frequency }}</MediumResultInfo>
            <MediumResultInfo title="Dichtheid">{{ density }}%</MediumResultInfo>
        </div>
    </Card>
</template>

<script>
    import Card from "../../Card";
    import CardHeader from "../../CardHeader";
    import MediumResultInfo from "../../MediumResultInfo";
    import BigResultInfo from "../../BigResultInfo";

    export default {
        name: "FocusKeywordDensity",
        props: {
            result: Object
        },
        components: {
            BigResultInfo,
            Card,
            CardHeader,
            MediumResultInfo
        },
        computed: {
            density() {
                return this.result.density.toLocaleString('nl-NL', {
                    maximumFractionDigits: 2
                });
            }
        }
    }
</script>

<style scoped>
</style>
