<template>
    <div class="performance-score">
        <div>
            <h2>Performance score</h2>
            <ScoreCircle :score="score" />
        </div>
    </div>
</template>

<script>
    import ScoreCircle from "../../ScoreCircle";
    export default {
        name: "PerformanceScore",
        props: {
            score: {
                type: Number,
                required: true
            }
        },
        components: {ScoreCircle}
    }
</script>

<style scoped lang="scss">
    #cube-page-analyser .performance-score {
        height: 100%;
        padding-top: 36px;

        > div {
            background-color: var(--pa-lightgrey);
            border-radius: 10px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            height: 100%;

            h2 {
                margin-top: 0 !important;
            }

            > .score-container {
                display: flex;
                flex-grow: 2;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>