import Vue from 'vue'
import axios from 'axios';

import App from './App.vue'
import Donut from 'vue-css-donut-chart';

import 'vue-css-donut-chart/dist/vcdonut.css';
import "./assets/style/app.scss";

Vue.config.productionTip = false;
Vue.use(Donut);

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.baseURL = "https://page-analyser.cube.nl/api/";

new Vue({
  render: h => h(App),
}).$mount('#cube-page-analyser-app');
